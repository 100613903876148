import {Alert, AlertIcon, Box, Button, Flex, FormLabel, Heading, Image, Input, Link, Stack,} from '@chakra-ui/react';
import {Form, Formik, useField} from "formik";
import * as Yup from 'yup';
import {errorNotification} from "../../services/notification";
import {useAuth} from "../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const MyTextInput = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (<Box>
        <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>
        <Input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (<Alert className="error" status={"error"} mt={2}>
            <AlertIcon/>
            {meta.error}
        </Alert>) : null}
    </Box>);
};

const LoginForm = () => {
    const {login} = useAuth();
    let navigate = useNavigate();
    return (<Formik
        validateOnMount={true}
        validationSchema={Yup.object({
            username: Yup.string().email("Must be valid email").required("Email is required"),
            password: Yup.string().max(20, 'Password cannot be more than 20 characters').required("Password is required"),
        })}
        initialValues={{username: '', password: ''}}
        onSubmit={(values, {setSubmitting}) => {
            setSubmitting(true);
            login(values)
                .then(res => {
                    console.log("Successfully logged in ");
                    navigate('/dashboard/customers');
                })
                .catch(err => {
                    console.log(err);
                    errorNotification(
                        err.code,
                        err.response?.data.message
                    );
                })
                .finally(() => {
                    setSubmitting(false);
                })
            ;
        }}>
        {(isValid, isSubmitting) => (<Form>
                <Stack spacing={12}>
                    <MyTextInput
                        label={"Email"}
                        name={"username"}
                        type={"email"}
                        placeholder={"hello@divineaura.com"}
                    />
                    <MyTextInput
                        label={"Password"}
                        name={"password"}
                        type={"password"}
                        placeholder={"Type your password"}
                    />
                    <Button
                        type={"submit"}
                        disabled={!isValid || isSubmitting}
                    >
                        Login
                    </Button>
                </Stack>
            </Form>

        )}
    </Formik>);
}
const Login = () => {
    const navigate = useNavigate();
    const {customer } = useAuth();

    useEffect(() => {
        if(customer) {
            navigate("/dashboard/customers")
        }
    })

    return (<Stack minH={'100vh'} direction={{base: 'column', md: 'row'}}>
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
                <Heading fontSize={'2xl'} mb={5}>Sign in to your account</Heading>
                <LoginForm/>
                <Link color={"blue.500"} href={"/signup"}>
                    Dont have an account? Signup now.
                </Link>

            </Stack>
        </Flex>
        <Flex flex={1}>
            <Image
                alt={'Login Image'}
                objectFit={'cover'}
                src={'https://as2.ftcdn.net/v2/jpg/06/14/35/79/1000_F_614357971_YgxMvjD3kB8brjOxPWWY0356ZirgcGzL.jpg'}
            />
        </Flex>
    </Stack>);
}

export default Login;